import React, { useState, useCallback } from "react"
import { Modal, Dropdown } from "react-bootstrap"
import { Formik } from "formik"
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input"
import crosswhite from "../../assets/images/crosswhite.png"
import MailIcon from "../../assets/images/icons/mail.svg"
import * as axios from "axios"
import Swal from "sweetalert2"
import Cookies from "universal-cookie"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import PhoneDropdown from "./phone-dropdown"
import EmailInput from "./email-input"
import validateEmail from "../../utils/validate-email"
import { saveAs } from "file-saver"

const cookies = new Cookies()

const Whitepaper = ({
  btnText,
  btnSmallText,
  styler,
  title,
  description,
  type,
  submitBtnText,
  pageContext: { translate },
}) => {
  const roleList = [
    {
      label: translate.t?.roaster,
      value: "roaster",
    },
    {
      label: translate.t?.estate,
      value: "estate",
    },
    {
      label: translate.t?.facilitator,
      value: "facilitator",
    },
    {
      label: "Micro-Roaster",
      value: "micro-roaster",
    },
    {
      label: translate.t?.hospitality,
      value: "hospitality",
    },
  ]

  const sizeList = [
    {
      label: "<10",
      value: "<10",
    },
    {
      label: ">50",
      value: ">50",
    },
    {
      label: ">500",
      value: ">500",
    },
    {
      label: ">1000",
      value: ">1000",
    },
  ]

  const [show, setShow] = useState(false)
  const [step, setStep] = useState(0)
  const [countryCode, setCountryCode] = useState("+46")

  const { executeRecaptcha } = useGoogleReCaptcha()
  const processReCaptcha = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available")
        return null
      }

      const token = await executeRecaptcha("submitForm")
      return token || null
    } catch (e) {
      return null
    }
  }, [executeRecaptcha])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const onSubmit = async (values, setSubmitting, errors, setErrors) => {
    let emailValidated = await validateEmail(values.email)
    let phoneValidated =
      isValidPhoneNumber(values.phone) || !values.phone?.length

    if (!phoneValidated) {
      return setErrors({
        ...errors,
        phone: "Invalid phone number",
      })
    }

    if (!emailValidated) {
      return setErrors({
        ...errors,
        email: "Invalid Email Address",
      })
    }

    const token = await processReCaptcha()

    if (!token) {
      return Swal.fire({
        icon: "error",
        text: translate.t?.recaptcha_failed,
        confirmButtonColor: "#30855c",
        timer: 3000,
      })
    }

    const url = type === "contact" ? "/leads" : "/whitepaper-download"
    const body =
      type === "contact"
        ? {
            api_call: url,
            data: {
              name: values.name,
              email: values.email,
              interested_in: values.role.value,
              company_name: values.company,
              phone: values.phone,
              utm_campaign: cookies.get("utm_campaign"),
              utm_medium: cookies.get("utm_medium"),
              utm_source: cookies.get("utm_source"),
              "g-recaptcha-response": token,
            },
          }
        : {
            api_call: url,
            data: {
              name: values.name,
              email: values.email,
              company_name: values.company,
              company_size: values.size.value.toString(),
              utm_campaign: cookies.get("utm_campaign"),
              utm_medium: cookies.get("utm_medium"),
              utm_source: cookies.get("utm_source"),
              "g-recaptcha-response": token,
            },
          }
    axios
      .post(`${process.env.GATSBY_API_URL}`, body, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          setStep(1)

          if (type !== "contact") {
            saveAs(
              `${process.env.GATSBY_BED_API}/public/eraofwe-whitepaper-2.pdf`,
              "Era of We - Whitepaper.pdf"
            )
          }
        } else {
          Swal.fire({
            icon: "error",
            text: translate.t?.failed_to_send,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        }
        setSubmitting(false)
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          icon: "error",
          text: translate.t?.failed_to_send,
          confirmButtonColor: "#30855c",
          timer: 3000,
        })
        setSubmitting(false)
      })
  }

  const onReset = () => {
    setShow(false)
    setStep(0)
  }

  return (
    <>
      {
        <div className="model-box">
          <div>
            <button className={`btndefault ${styler}`} onClick={handleShow}>
              {btnText}{" "}
              {btnSmallText && (
                <span className="text-small pt-1">{btnSmallText}</span>
              )}
            </button>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <img
                src={crosswhite}
                alt="Close"
                className="img-close-diolog"
                onClick={() => handleClose()}
              />
              <Modal.Body>
                {step === 0 && (
                  <div className="details">
                    <p className="paragraph-p1 bold text-medium">{title}</p>
                    <p className="paragraph-p16  text-medium">{description}</p>
                  </div>
                )}
                {step === 0 && (
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      company: "",
                      size: sizeList[0],
                      phone: "",
                      role: roleList[0],
                    }}
                    validate={values => {
                      const errors = {}
                      if (!values.email) {
                        errors.email = translate.t?.required
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address"
                      }
                      if (!values.name) {
                        errors.name = translate.t?.required
                      }
                      if (!values.company) {
                        errors.company = translate.t?.required
                      }
                      if (!values.size) {
                        errors.size = translate.t?.required
                      }
                      if (!values.phone && type === "contact") {
                        errors.phone = translate.t?.required
                      }
                      if (!values.role) {
                        errors.role = translate.t?.required
                      }
                      return errors
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setSubmitting,
                      setFieldValue,
                      setErrors,
                      /* and other goodies */
                    }) => (
                      <form
                        onSubmit={event => {
                          event.preventDefault()
                          onSubmit(values, setSubmitting, errors, setErrors)
                        }}
                      >
                        <div className="form-grouper form-field">
                          <label htmlFor="name">
                            {translate.t?.full_name}
                            <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            className={`${
                              errors.name && touched.name ? "invalid" : ""
                            }`}
                            placeholder="e.g. John Doe"
                          />
                          {errors.name && touched.name && (
                            <div className="error">{errors.name}</div>
                          )}
                        </div>
                        {type === "download" && (
                          <div className="form-grouper form-field">
                            <label htmlFor="email">
                              {translate.t?.email_address}
                              <sup>*</sup>
                            </label>
                            <EmailInput
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              placeholder="e.g. john.doe@example.com"
                              className={`${
                                errors.email && touched.email ? "invalid" : ""
                              }`}
                              setError={() => {
                                setErrors({
                                  ...errors,
                                  email: "Invalid Email Address",
                                })
                              }}
                            />
                            {errors.email && touched.email && (
                              <div className="error">{errors.email}</div>
                            )}
                          </div>
                        )}
                        <div className="form-grouper form-field">
                          <label htmlFor="company">
                            {translate.t?.company_name}
                            <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            name="company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company}
                            placeholder={translate.t?.whats_company_name}
                            className={`${
                              errors.company && touched.company ? "invalid" : ""
                            }`}
                          />
                          {errors.company && touched.company && (
                            <div className="error">{errors.company}</div>
                          )}
                        </div>
                        {type === "download" && (
                          <div className="form-grouper form-field">
                            <label htmlFor="size">
                              {translate.t?.company_size}
                              <sup>*</sup>
                            </label>
                            <div className="custom-dropdown">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  {values.size.label}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-select">
                                  {sizeList?.map((size, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setFieldValue("size", size)
                                      }}
                                    >
                                      {size.label}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {errors.size && touched.size && (
                              <div className="error">{errors.size}</div>
                            )}
                          </div>
                        )}
                        {type === "contact" && (
                          <div className="form-grouper dual align-items-start">
                            <div className="first form-field">
                              <label htmlFor="email">
                                {translate.t?.enter_email}
                                <sup>*</sup>
                              </label>
                              <EmailInput
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="e.g. john.doe@example.com"
                                className={`${
                                  errors.email && touched.email ? "invalid" : ""
                                }`}
                                setError={() => {
                                  setErrors({
                                    ...errors,
                                    email: "Invalid Email Address",
                                  })
                                }}
                              />
                              {errors.email && touched.email && (
                                <div className="error">{errors.email}</div>
                              )}
                            </div>
                            <div className="first form-field">
                              <label htmlFor="phone">
                                {translate.t?.phone_number}
                                <sup>*</sup>
                              </label>
                              <PhoneInput
                                name="phone"
                                countrySelectComponent={PhoneDropdown}
                                withCountryCallingCode={true}
                                international={true}
                                defaultCountry="SE"
                                placeholder={translate.t?.enter_phone_number}
                                onChange={e => {
                                  if (!e) return
                                  let phone = parsePhoneNumber(e)
                                  if (phone) {
                                    setFieldValue(
                                      "phone",
                                      `+${phone.countryCallingCode}-${phone.nationalNumber}`
                                    )
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.phone}
                                className={`${
                                  errors.phone && touched.phone ? "invalid" : ""
                                }`}
                              />

                              {errors.phone && touched.phone && (
                                <div className="error">{errors.phone}</div>
                              )}
                            </div>
                          </div>
                        )}
                        {type === "contact" && (
                          <div className="first form-field">
                            <label htmlFor="role">
                              {translate.t?.product_interested_for}
                              <sup>*</sup>
                            </label>
                            <div className="custom-dropdown">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  {values.role.label}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-select">
                                  {roleList?.map((role, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        setFieldValue("role", role)
                                      }}
                                    >
                                      {role.label}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        )}
                        <button
                          className="btndefault greenbtn"
                          type="submit"
                          disabled={
                            isSubmitting ||
                            (errors &&
                              Object.values(errors)?.filter(e => e.length)
                                ?.length)
                          }
                        >
                          {submitBtnText}
                        </button>
                      </form>
                    )}
                  </Formik>
                )}
                {step === 1 && (
                  <div className="d-flex flex-column align-items-center complete-modal-content">
                    <img alt="Mail Icon" src={MailIcon} width="87" />
                    <p className="pt-4 pt-lg-5 paragraph-p1 bold pb-lg-2">
                      {type === "contact"
                        ? `${translate.t?.details_received} ${translate.t?.we_will_contact_soon}`
                        : translate.t?.whitepaper_downloaded}
                    </p>
                    <button
                      className="btndefault greenbtn mt-4"
                      onClick={() => onReset()}
                    >
                      {translate.t?.back_to_site}
                    </button>
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      }
    </>
  )
}

export default Whitepaper
